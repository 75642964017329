import React from 'react'
import logoTransparent from './Assets/Asset-2.jpg'
import sign from './Assets/Under-Construction-Sign2.png'

const UnderConstruction = () => {
  return (
    
        <div className='w-full h-full grid grid-cols-1 p-4  bg-black'>            
            
            <div className='m-auto h-auto'>
                <div className=' w-full  grid grid-cols-1 lg:grid-cols-2 bg-black p-4 mt-20'>                    
                    <div className='max-w-[500px] m-auto'>
                    <img src={sign} alt="Under Construction" />
                    </div> 
                    <div className='container m-auto '>
                                        <form action="https://getform.io/f/paygrxla" method="POST" enctype="multipart/form-data" className='flex flex-col max-w-[500px]  '>
                                            <p className='text-[#febd17] text-center text-lg my-6 font-semibold '>While our website is under construction <br /> <span className='text-xl'>Wall-Pro Interiors</span> remains business as usual. <br /> 
                                            <span className='text-xl'>Calgary's</span> first choice for <br /><span className='text-xl'>Quality</span> and <span className='text-xl'>Customer Service</span>.<br></br><br />
                                                                            Contact <span className='text-xl'>Wall-Pro Interiors</span> by Email or Phone. </p>
                                            <p className='text-[#febd17]'>Name</p>
                                            <input className='my-1 p-2 bg-[#febd17] shadow-inner shadow-gray-800/90 text-black border-2 border-yellow-500' type="text" name='name' />
                                            <p className='text-[#febd17]'>Email</p>
                                            <input className='my-1 p-2 bg-[#febd17] text-black shadow-inner shadow-gray-800/90 border-2 border-yellow-500' type="email" name='email' />
                                            <p className='text-[#febd17]'>Message</p>
                                            <textarea className='my-1 p-2 bg-[#febd17] text-black shadow-inner shadow-gray-800/90 border-2 border-yellow-500' name="message" rows={6}></textarea>
                                            <input className='mt-1 p-2 bg-[#febd17] text-black' type='file' name='file[]'/><br />
                                            <button className='px-2 py-1 mx-auto flex items-center bg-[#febd17] text-black font-semibold shadow-inner shadow-gray-800/60 hover:scale-95 border-2 border-yellow-500'  type='submit'>Submit</button>
                                        </form>                                
                    </div>         
                </div>
            </div>
        </div>

        
        
    
  )
}

export default UnderConstruction