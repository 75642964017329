import React from 'react'
import {AiFillPhone, AiOutlineClockCircle} from 'react-icons/ai'
import Logo from './Assets/logoSmall.jpg'
import logoTransparent from './Assets/Asset-2.jpg'

const TopBar = () => {
  return (
    <div className='flex justify-between items-center  py-2 px-4 bg-[#000000]'>
        {/* <div className='flex items-center mt-2'>
        <img src={Logo} alt='Main Image'/>
        </div>
        <div className='flex pr-4'>
            <div  className='hidden md:flex items-center font-medium'>
                <AiOutlineClockCircle size={20} className='mr-1 text-black '/>
                <p className='text-base text-black'>7AM - 7PM</p>
            </div>
            <div className='hidden md:flex items-center px-2 font-medium'>
                <AiFillPhone size={20} className='mr-1 text-black '/>
                <p className='text-base text-black'>587-432-0481</p>
            </div>
            <button className='font-medium hover:scale-[98%] duration-100 shadow-md'>SCHEDULE CONSULTATION</button>
        </div> */}

<div className='mx-auto'><img src={logoTransparent} alt="Wall-Pro 2024" /></div>

    </div>
  )
}

export default TopBar