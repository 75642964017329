
import UnderConstruction from "./Components/UnderConstruction";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import TopBar from "./Components/TopBar";

function App() {
  return (
    <div>
      {/*
        <TopBar />
        <NavBar />
        <Slider />
        <Home />   
        <NavBar />
        <Slider />
        
        */}
        <TopBar />
        <NavBar />
        <UnderConstruction />
        <Footer />  
        
        
    </div>
  );
}

export default App;
