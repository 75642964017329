import React, {useState} from 'react'
import {FaBars, FaFacebookF, FaInstagram, FaLinkedinIn, FaGooglePlusSquare,} from 'react-icons/fa'

const NavBar = () => {
const [nav, setNav] = useState(false)

const handleNav = () => {
    setNav(!nav)
}

const [isMenuOpen, setIsMenuOpen] = useState(false);

const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};

  return (
    <div className='w-full h-[40px] flex justify-between items-center absolute z-10 text-[#febd17] bg-black'>
        
        <ul className='hidden sm:flex pr-2 font-normal text-base drop-shadow-xl items-center'>
            <li className='font-medium p-4'>
                <a href="/">HOME</a>
            </li>
            <li className="inline-block relative group  hover:bg-[#febd17] hover:text-black px-4 py-1 font-medium">
              <a href="/" className="hover:text-black">ABOUT</a>
              <ul className="absolute left-0 hidden mt-1 bg-[#febd17] text-black group-hover:block">
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] group-hover:block border-b border-black">PHILOSOPHY</a>
                </li>
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] group-hover:block border-b border-black">STANDARDS</a>
                </li>
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] group-hover:block ">WARRANTY</a>
                </li>
              </ul>
            </li>
            <li className="inline-block relative group  hover:bg-[#febd17] hover:text-black  px-4 py-1 font-medium">
              <a href="/" className="hover:text-black">SERVICES</a>
              <ul className="absolute left-0 hidden mt-1 bg-[#febd17]  text-black group-hover:block">
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] hover:block border-b border-black">INSULATION</a>
                </li>
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] border-b border-black">DRYWALL&nbsp;/&nbsp;DRYWALL&nbsp;FINISHING</a>
                </li>                
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17] border-b border-black">RENO&nbsp;/&nbsp;REPAIR&nbsp;/&nbsp;RESTORATION</a>
                </li>
                <li>
                  <a href="/" className="block p-[6px] hover:bg-[#febd17]">COMMERCIAL&nbsp;/&nbsp;RESIDENTIAL</a>
                </li>
              </ul>
            </li>
            <li className='border- border-[#febd17] p-4 font-medium'>
                <a className='' href="#contact">CONTACT</a>
            </li>
        </ul>
        <div className='flex justify-between mr-4'>
            <FaFacebookF size={19} className='ml-3 ' />
            <FaInstagram size={20} className='ml-3 mr-1' />
            <FaLinkedinIn size={20} className='ml-3 mr-1' />
            <FaGooglePlusSquare size={20} className='ml-3 mr-0' />               
        </div>

        {/* Hamburger Icon */}
        <div onClick={handleNav} className='sm:hidden z-10'>
            <FaBars size={20} className='mr-4 cursor-pointer'/>
        </div>

        {/* Mobile Menu */}
        <div 
            onClick={handleNav}
            className={
                nav 
                ?'overflow-y-hidden sm:hidden ease-out duration-300 absolute text-[#febd17] left-0 top-0 h-screen w-full bg-black/90 px-4 py-7 flex flex-col' 
                : 'absolute top-0 h-screen left-[-100%] ease-in duration-300  py-7'
            }
        >
            <ul className='w-full h-full text-center font-medium pt-12'>
                <li className='text-2xl py-4 border-y border-[#febd17]'>
                    <a className='' href="/">Home</a>
                </li>
                <li className='text-2xl py-4 border-b border-[#febd17]'>
                    <a href="#about">About</a>
                </li>
                <li className='text-2xl py-4 border-b border-[#febd17]'>
                    <a href="#services">Services</a>
                </li>
                <li className='text-2xl py-4 border-b border-[#febd17]'>
                    <a href="#contact">Contact</a>
                </li>
            </ul>
        </div>
       

    </div>
  )
}

export default NavBar