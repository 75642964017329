import React from 'react'
import {FaFacebookF, FaGooglePlusSquare, FaInstagram, FaLinkedinIn, FaYoutube, } from 'react-icons/fa'
import bd from './Assets/bd.png'

const Footer = () => {
  return (
    <div className='w-full bg-black text-[#febd17] pt-20 '>
        <div className='w-full  grid grid-cols-1 sm:grid-cols-3  pb-4'>
            
            <div className='mx-28 pt-8 sm:pt-0 sm:mx-auto'>
                <h6 className='font-medium uppercase text-lg border-b border-[#febd17]'>ABOUT</h6>
                <ul className='pt-2 text-sm'>
                    <li className='font-medium my-1'>PHILOSOPHY</li>
                    <li className='font-medium my-1'>STANDARDS</li>
                    <li className='font-medium my-1'>WARRANTY</li>
                </ul>
            </div>
            <div className='mx-28 py-8 sm:mx-auto sm:py-0'>
                <h6 className='font-medium uppercase text-lg border-b border-[#febd17]'>SERVICES</h6>
                <ul className='pt-2 text-sm'>
                    <li className='font-medium my-1'>INSULATION</li>
                    <li className='font-medium my-1'>DRYWALL&nbsp;/&nbsp;DRYWALL&nbsp;FINISHING</li>
                    <li className='font-medium my-1'>PAINTING / TEXTURE</li>
                    <li className='font-medium my-1'>RENO&nbsp;/&nbsp;REPAIR&nbsp;/&nbsp;RESTORATION</li>
                    <li className='font-medium my-1'>COMMERCIAL&nbsp;/&nbsp;RESIDENTIAL</li>
                </ul>
            </div>            
            <div className='mx-28 sm:mx-auto'>
                <h6 className='font-medium uppercase text-lg border-b border-[#febd17]'>CONTACT</h6>
                <ul className='pt-2 text-sm'>
                    <li className='font-medium my-1'>CALGARY, AB.</li>
                    <li className='font-medium my-1'>[587] 432-0481</li>
                    <li className='font-medium my-1'>INFO@WALL-PRO.CA</li>
                </ul>
            </div>
        </div>

        <div className='flex flex-col w-full pb-2 pt-4 px-4 justify-between sm:flex-row text-center text-[#febd17]'>
            <p className='py-4 pl-2 text-xs font-medium'>&#169; 2024 WALL-PRO INTERIORS LTD. ALL RIGHTS RESERVED</p>
            <div className='flex justify-between items-center sm:w-[300px] py-4 text-2xl'>
                <FaFacebookF size={20}/>
                <FaInstagram />
                <FaLinkedinIn />                
                <FaGooglePlusSquare />
                <img src={bd} className='h-[27px] mr-2' alt='BDigital Media'/>                
            </div>
        </div>
    </div>
  )
}

export default Footer